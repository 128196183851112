import React from 'react';

import SEO from '../primitives/Seo';
import { homePath } from '../../routing/urlGenerator';
import { Link } from 'gatsby';
import ContentBlock from '../primitives/contentBlock/ContentBlock';
import ContentBlockMain from '../primitives/contentBlock/components/ContentBlockMain';
import ContentContainer from '../primitives/contentBlock/components/ContentContainer';

const PageNotFound = () => (
    <>
        <SEO title="404: Not found" />
        <ContentBlock>
            <ContentBlockMain>
                <ContentContainer>
                    <h1>NOT FOUND</h1>
                    <p>De pagina die je zoekt bestaat niet (meer).</p>
                    <Link to={homePath}>Terug naar de homepage</Link>
                </ContentContainer>
            </ContentBlockMain>
        </ContentBlock>
    </>
);

export default PageNotFound;
